body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}

.map {
  height: 100%;
}

.sidebar {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
}

.key {
  z-index: 999;
  position: absolute;
  bottom: 0;
  left: 0;
}

.extLinksContainer {
  display: flex;
  flex-direction: row;
}

.extlinkbtn {
  height: 24px;
}

.jsoneditor {
  z-index: 999;
}

.keyIcon {
  width: 20px;
}

.linkButton {
  width: 20px;
  margin-right: 2px;
}

.fuel {
  width: 20px;
}

.nav-item {
  background-color: #eee;
  border-color: #dee2e6;
}

.title {
  z-index: 999;
  position: absolute;
  top: 10px;
  left: 50px;
  width: 40%;
}

.card-covid {
  padding: 10px;
}

.p-covid-card {
  margin-bottom: 0;
}

.minimap, .minimap-overview {
  height: 400px;
  width: 400px;
}

.card-container {
  float: left;
}

.table-nonfluid {
  width: auto !important;
}

.myeditor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.jsoneditor-column  {
	/* necessary */
	height: 100%;
	min-height: 100%;
}

.jsoneditor-column {
	/* for the flex container */
	display: flex;
	flex-direction: column;
}

.jsoneditor-column .jsoneditor-container {
	flex: 1;
	overflow-y: auto;
}
